const Planning = {
    state: {
        planningList: []
    },
    mutations: {
        ADD_PLANNING(state, plan) {
            state.planningList.push(plan);
        },
        REFRESH_PLANNING(state) {
            //state.planningList.length = 0;
            state.planningList.splice(0, state.planningList.length);
        },
        ADD_PLANNING_UNIT(state, payload) {
            let tempPlan = state.planningList.find(function (item) {
                return item.term_key === payload.term && item.year_id === payload.year;
            });
            if (tempPlan) {
                // alert(JSON.stringify(payload));
                tempPlan.units.push(payload.unit);
            }
        },
        setChallenge(state, payload) {
            state.challenge = payload;
        }
    },
    getters: {
        planningList: state => state.planningList
    }
};

export default Planning;

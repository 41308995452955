import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueSweetalert2 from 'vue-sweetalert2';

import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

import '@/assets/styles/tailwind.css'


import './registerServiceWorker'

Vue.prototype.$orderBy = require('lodash.orderby')

Vue.config.productionTip = false;


Vue.prototype.$version = require('../package.json').version

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

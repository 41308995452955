import User from "@/models/user";
import Access from "@/models/access";
import Settings from "@/models/settings";
import { BaseService } from "@/services/school.service";
import { SettingsService } from "@/services/settings.service"

const state = {
    status: '',
    user: {},
    access: {},
    token: '',
    config: [],
}

const mutations = {
  auth_request(state){
    state.status = 'loading'
  },
  auth_success(state, { token, user, access }) {
    state.status = 'success'
    state.token = token
    state.access = access
    state.user = user

  },
  auth_error(state){
    state.status = 'error'
  },
  logout(state) {
    state.token = ''
    state.status = ''
    state.user = ''
    state.access = ''
    state.config = ''
  },
  configure(state, {config}) {
    state.config = config
  },
  auth_access(state, {access}) {
    state.access = access
  }
}

const getters = {
  token: state => state.token,
  currentUser: state => User.from(state.user),
  isLoggedIn: state => !!state.token,
  authStatus: state => state.status,
  currentAccess: state => Access.from(state.access),
  settings: state => Settings.from(state.config)
}

const actions = {
  login ({ commit }, user) {
    return new Promise((resolve, reject) => {
        commit('auth_request')
        BaseService.create("authenticate/login", user )
        .then(response => {

            const token = response.data.token
            const user = response.data.user
            const access = response.data.modules

            commit('auth_success', { token, user, access })
            resolve(response)
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('pepapp-persist')

          if (!err.response) {
            reject("network_error")
         } else {
            console.log("XXXXX", JSON.stringify(err))
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
            reject(err)
          }   
        })
    })
  },

  configure({ commit }) {
    return new Promise((resolve, reject) => {
        SettingsService.getAll()
        .then(response => {

            const config = response.data

            commit('configure', { config })
            resolve(response)
        })
        .catch(err => {
            reject(err)
        })
    })
    
  },


  setAccess({ commit }, access) {
    return new Promise((resolve) => {
         commit('auth_access', {access})
          resolve()
    })
    
  },

  logout ({ commit }) {
    return new Promise((resolve) => {
            commit('logout')
            localStorage.removeItem('pepapp-persist')
            resolve()
        })
    
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}

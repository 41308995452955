import JwtDecode from "jwt-decode";
import { Grades, AssessmentCriteria, DisplayCriteria, YearTitles, Fields} from "@/models/configuration"


export default class Settings {
  static from(results) {
    try {
      return new Settings(results);
    } catch (err) {
      console.log("error - no settings data", err);
      return err;
    }
  }

  constructor(initializer) {
    this.gradeList = this.gradeList(initializer)
    this.display = this.display(initializer)
    this.assessmentCriteria = this.assessmentCriteria(initializer)
    this.yearTitles = this.yearTitles(initializer)
    this.fields = this.fields(initializer)
  }


  gradeList(data) {
      var grades = data.filter(function(item) {
       return item.key === Grades.KEY_STRING;
     });
     let gradeValues = grades.length > 0 ? JSON.parse(grades[0].value) : undefined;

     return Grades.from(gradeValues);
  }

  display(data) {
      var criteria = data.filter(function(item) {
        return item.key === DisplayCriteria.KEY_STRING;
      });

      let criteriaValues = criteria.length > 0 ? JSON.parse(criteria[0].value) : {};
   
     return DisplayCriteria.from({values: criteriaValues});
  }

  assessmentCriteria(data) {
    var criteria = data.filter(function(item) {
      return item.key === AssessmentCriteria.KEY_STRING;
    });

    let criteriaValues =
      criteria.length > 0 ? JSON.parse(criteria[0].value) : undefined;

    return AssessmentCriteria.from(criteriaValues);
  }

  yearTitles(data) {
    var yeartitles = data.filter(function(item) {
      return item.key === YearTitles.KEY_STRING;
    });

    let titlesValues = yeartitles.length > 0 ? JSON.parse(yeartitles[0].value) : undefined
    return YearTitles.from(titlesValues)
  }

  fields(data) {
      var fieldData = data.filter(function(item) {
        return item.key === Fields.KEY_STRING;
      });

      let fieldValues = fieldData.length > 0 ? JSON.parse(fieldData[0].value) : undefined;

      return Fields.from(fieldValues);

  }
}


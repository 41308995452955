const Persistance = {
  state: {
    unit: {},
    challenge: {},
  },
  mutations: {
    setUnit(state, payload) {
      state.unit = payload
    },
    setChallenge(state, payload) {
      state.challenge = payload
    },
  },
  getters: {
    unit: state => state.unit,
    challenge: state => state.challenge,
  }
};

export default Persistance;

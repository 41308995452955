import Vue from "vue";
import Vuex from "vuex";

import createPersist from 'vuex-localstorage'
import auth from './store/auth'
import persist from './store/persist'
import planning from "./store/planning";

Vue.use(Vuex);

export default new Vuex.Store({
	plugins: [ createPersist({
    namespace: 'pepapp-persist',
    initialState: {},
    expires: 7 * 24 * 60 * 60 * 1000
  }) ],
    modules: {
    auth,
    persist,
    planning
  }
});
import JwtDecode from "jwt-decode";

export default class User {
  static from(object) {
    try {
      return new User(object);
    } catch (err) {
      console.log("error - no user");
      return err;
    }
  }

  constructor(initializer) {
    this.id = initializer.school_id
    this.name = initializer.name;
    this.school = initializer.school;
    this.type = initializer.type;
    this.style = initializer.style.String;
  }

  // get isAdmin () {
  //   return this.admin
  // }

  get schoolID() {
    return this.id;
  }

  get schoolName() {
    return this.school;
  }

  get schoolType() {
    return this.type;
  }

  get isSubscriber() {
    return this.type != "trial";
  }

  get typeID() {
    return this.type == "organisation" ? 4 : 3;
  }

  get isSchool() {
    return this.type != "organisation";
  }

  get schoolStyle() {
    return this.style;
  }

  get schoolBackground() {
    switch (this.type) {
      case "secondary": 
      return "secondary-blue";
      break;
      case "gcse": 
      return "gcse-teal";
      break;
      case "international": 
      return "international-purple";
      break;
      default:
      return "pink-red";
    }
    
  }

  get schoolLogo() {
      switch (this.type) {
      case "secondary": 
      return "secondary-passport-logo";
      break;
      case "gcse": 
      return "gcse-passport-logo";
      break;
      case "international": 
      return "secondary-passport-logo";
      break;
      default:
      return "primary-passport-logo";
    }
  }

}

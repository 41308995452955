
class Grades {
  static from(object) {
    if (object == undefined) {
      return Grades.defaultValues()
    } else {
        try {
          return new Grades(object).displayValues();
        } catch (err) {
          console.log("error - no grades", err);
          return err;
        }
    }

  }

  constructor(initializer) {
    this.gold = initializer.gold;
    this.silver = initializer.silver;
    this.bronze = initializer.bronze;
    this.greater = initializer.greater;
  }

  asJSON() {
    return JSON.stringify({
      gold: this.gold,
      silver: this.silver,
      bronze: this.bronze,
      greater: this.greater
    });
  }

  displayValues() {
    return [
      { key: "bronze", value: this.bronze, color: 'text-orange-800', background: 'bg-orange-300', low: 1.0,
          high: 1.68,},
      { key: "silver", value: this.silver,color: 'text-gray-700', background: 'bg-gray-300',  low: 1.68,
          high: 2.34, },
      { key: "gold", value: this.gold, color: 'text-yellow-500', background: 'bg-yellow-300', low: 2.34,
          high: 3.1, },
      { key: "greater", value: this.greater, color: 'text-teal-700', background: 'bg-teal-300',  low: 3.1,
          high: 5.1 },
    ];
  }

  static defaultValues() {
    return [
      { key: "bronze", value: "Bronze", color: 'text-orange-800', background: 'bg-orange-300', low: 1.0,
          high: 1.68,},
      { key: "silver", value: "Silver", color: 'text-gray-700', background: 'bg-gray-300', low: 1.68,
          high: 2.34, },
      { key: "gold", value: "Gold", color: 'text-yellow-500', background: 'bg-yellow-300', low: 2.34,
          high: 3.1, },
      { key: "greater", value: "Greater Depth", color: 'text-teal-700', background: 'bg-teal-300', low: 3.1,
          high: 5.1 },
    ];
  }

  static KEY_STRING = "assessment_grades"
}

// display criteria
class DisplayCriteria {
  static from(object) {
    try {
      return new DisplayCriteria(object);
    } catch (err) {
      console.log("error - no criteria", err);
      return err;
    }
  }

  constructor(initializer) {
    this.outcomes = initializer.values.outcomes;
    this.courses = initializer.values.courses;
    this.planningRows = initializer.values.plan_rows
  }

  valuesAsJSON() {
    return JSON.stringify({
      outcomes: this.outcomes,
      courses: this.courses,
      plan_rows: this.planningRows,
    });
  }

  static KEY_STRING = "display_criteria"
}


// assessment criteria
class AssessmentCriteria {
   static from(array) {
    var values = AssessmentCriteria.defaultValues()
    if (array != undefined) {
      values = array.map(object => {
            return new AssessmentCriteria(object)
          });
    }
     
    return values.sort((a, b) => a.order - b.order);
  }

  constructor(initializer) {
    this.key = initializer.key;
    this.value = initializer.value;
    this.order = initializer.order;
    this.initials = initializer.initials;
  }

  static defaultValues() {
    return [
      { key: "performance", value: "Performance", order: 1 },
      { key: "collaboration", value: "Social and Development", order: 2 },
      { key: "competition", value: "Competition", order: 3 },
    ];
  }

  static KEY_STRING = "assessment_criteria"
}


// year titles
class YearTitles {
  static from(array) {
    var values = []
    if (array != undefined) {
      values = array.map(object => {
            return new YearTitles(object)
          });
    }
    
    return values
  }

  constructor(initializer) {
    this.value = initializer.value;
    this.title = initializer.title;
  }

  static KEY_STRING = "year_titles"
}



// outcome config
class OutcomeConfiguration {
  static from(object) {
    try {
      return new OutcomeConfiguration(object);
    } catch (err) {
      console.log("error - no criteria", err);
      return err;
    }
  }

  constructor(initializer) {
    this.id = initializer.id;
    this.active = initializer.values.active;
  }

  valuesAsJSON() {
    return JSON.stringify({
      active: this.active
    });
  }

  //static KEY_STRING = "outcome_config"
}

// lesson form fields
class Field {
  static from(object) {
    try {
      return new Field(object);
    } catch (err) {
      console.log("error - no field", err);
      return err;
    }
  }

  constructor(initializer) {
    this.name = initializer.name;
    this.value = initializer.value;
    this.order = initializer.order;
    this.used = initializer.used;
    this.protect = initializer.protect;
    this.model = initializer.model;
    this.filebased = initializer.filebased
    this.popover = initializer.popover

  }
}

class Fields {
  static from(object) {
    try {
      return new Fields(object);
    } catch (err) {
      console.log("error - no criteria", err);
      return err;
    }
  }


  static from(array) {
    var values = Fields.defaultValues()
    if (array != undefined) {
      values = array.map(object => {
            return Field.from(object)
          });
    }
     
    return values.sort((a, b) => a.order - b.order);
  }


  orderedFields(data) {
    let values = data.map(field => {
      return Field.from(field);
    });
    return values.sort((a, b) => a.order - b.order);

  }

  static defaultValues() {
    return [
      Field.from({
        name: "Outline",
        order: 1,
        used: true,
        protect: true,
        model: "outline",
        filebased: true,
        popover: false
      }),
      Field.from({ name: "Introduction", order: 2, used: true,model: "introduction",popover: true }),
      Field.from({ name: "Warm-Up", order: 3, used: true, model: "warm-up", popover: false }),
      Field.from({
        name: "Main Session",
        order: 4,
        used: true,
        protect: true,
        model: "main-session",
        filebased: true,
                popover: false
      }),
      Field.from({name: "Game Session", order: 5, used: true, model: "game-session", popover: false }),
      Field.from({name: "Competition", order: 6, used: false, model: "competition", popover: false }),
      Field.from({
        name: "Performance",
        order: 7,
        used: false,
        model: "performance",
        popover: false 
      }),
      Field.from({name: "Health & Wellbeing", order: 8, used: false, model: "health", popover: false }),
      Field.from({
        name: "Collaboration",
        order: 9,
        used: false,
        model: "collaboration", 
        popover: false 
      }),
      Field.from({  name: "Conclusion", order: 10, used: true, model: "conclusion", popover: false }),
      Field.from({ name: "Activities", order: 11, used: true, model: "activities", popover: true})
    ];
  }

  static KEY_STRING = "course_fields"
}

export { Grades, AssessmentCriteria, DisplayCriteria, Fields, OutcomeConfiguration, YearTitles };
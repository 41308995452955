<template>
  <div name="page">
    <div
      class="
        min-h-screen
        flex flex-col flex-auto flex-shrink-0
        antialiased
        bg-gray-100
        text-black
      "
    >
    
    <sidebarview 
            v-if="!$route.meta.loginLayout && !$route.meta.hideHeader"
        ref="sidebar"
        :parent="$route.meta.subnav"
        >
    </sidebarview>

      <div
        class="h-full mt-14"
        v-bind:class="[
          $route.meta.loginLayout || $route.meta.hideHeader
            ? 'ml-0'
            : 'mb-10 md:ml-56',
        ]"
      >
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>



<script>
const sidebarview = () => import("@/components/TheSidebar.vue");
import { mapGetters } from "vuex";

export default {
  name: "page",
  computed: {
    ...mapGetters({ currentUser: "currentUser" }),
    displaName() {
      return this.currentUser.schoolName;
    },
  },
  data: function () {
    return {};
  },
  methods: {
    toggleNav: function () {
      var current = this.$refs.sidebar.style.display;
      if (current == "" || current == "none") {
        this.$refs.sidebar.style.display = "block";
      } else {
        this.$refs.sidebar.style.display = "none";
      }
    },
    setBackground() {
      // console.log(this.$hostname)
      let login = this.$route.meta.loginLayout ? "login" : "";
      let subdomain = this.$primaryStyle == false ? this.$hostname : "";
      return login + " " + subdomain;
    },
    setMainClass() {
      var hideHeader = this.$route.meta.hideHeader ? "hide_header" : "";
      var css =
        this.$route.meta.showSide == undefined ||
        this.$route.meta.showSide == false
          ? ""
          : "ml-56";
      css = css + hideHeader;
      return css;
    },
    checkCurrentLogin() {
      // console.log(this.currentUser);
      // console.log(this.currentUser.schoolID);
      // console.log(localStorage.getItem('user'));
      // if (localStorage.getItem('user')) {
      //   console.log(JSON.parse(localStorage.getItem('user')));
      // }
      // if (this.currentUser) {
      //   this.isLoggedIn = true
      // } else {
      //   this.isLoggedIn = false
      // }
      //if (!this.currentUser && this.$route.path !== '/') {
      //this.$router.push(this.$route.path)
      //}
    },
  },
  components: {
    sidebarview,
  },
};
</script>

<style>


/*****
Inner Head
******/

.inner-head {
  @apply w-full bg-white px-6 pt-4 pb-4 border-b h-20;
}

.inner-head .subnav {
  @apply text-sm pl-1 pt-3;
}

.inner-head-title {
  @apply text-lg font-bold text-action-blue mr-6 pt-2 inline-block;
}

.inner-head-list-item {
  @apply inline-block mr-5 text-sm cursor-pointer;
}

.inner-head-list-item button {
  @apply opacity-50;
}

.inner-head-list-item > .active {
  @apply text-action-blue font-bold opacity-75;
}

/*****
Filter Head
******/

.filter-bar {
  @apply bg-white h-16 py-3 pl-8 text-gray-500;
}

.filter-button {
  @apply text-sm font-bold pr-3 pl-0 text-gray-700;
}

.filter-button i {
  @apply p-2 rounded-lg mr-1;
}

.filter-bar-search {
  @apply h-full  w-80  bg-white  border rounded  p-2 mr-3;
}



.panel-header {
  @apply pb-4 text-lg text-gray-700 font-bold;
}

.top-bar {
  @apply w-full   bg-white  text-right pr-12 pt-3 pb-5 border-solid border-b border-gray-200;
}

.top-bar .breadcrumb {
  @apply w-1/2 pt-4 pl-8 text-base capitalize text-left text-gray-500;
}

.action-button {
  @apply text-xs p-2 px-4 text-white bg-action-blue font-bold rounded-lg;
}
.action-button i {
  @apply text-sm pr-1
}

.action-list-item {
  @apply w-32 h-12 shadow-sm rounded-full  text-action-blue opacity-75  text-center cursor-pointer mx-1 text-lg ;
}


.action-list-item p {
  @apply -mt-4;
}

.pad-right {
  @apply pr-0 !important;
}

.close-icon {
  @apply align-middle ml-2 cursor-pointer text-black text-3xl;
}

.carret {
  @apply opacity-50 px-2;
}

.tooltip-info {
  @apply rounded bg-action-blue bg-opacity-75 text-white;
}

.tooltip-info .tooltip-arrow {
  @apply border-action-blue opacity-75;
}

.publish-button {
  @apply text-white bg-action-blue font-bold py-2  px-4 rounded-lg;
}

/*****
FORM
******/

.form-label {
  @apply block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2;
}

.form-icon-button {
  @apply font-bold py-2 px-4 rounded text-xs;
}

input[type="text"],
input[type="password"],
  textarea {
  @apply px-4 py-3 rounded bg-gray-100  mt-2 border w-full;
}

/*****
Side
******/

.sidebar-section-head {
   @apply mt-3 mb-4  pl-3 font-bold text-sm;
}

.side-list-item {
  @apply inline-block rounded py-2  px-3  w-full text-left text-gray-600 mb-1 ;
}

	  .sidebar .router-link-exact-active,
      .sidebar .active {
    @apply bg-gray-300;
	  }

.sidebar .router-link-exact-active i {
  @apply text-gray-500  opacity-100;
}


/*****************************
 **** SPINNER
 ***************************/

.spin-holder {
  @apply mt-8;
}

  .spinner {
    @apply text-action-blue opacity-75 text-6xl mt-4;
  }

   .spin-holder p {
   	   @apply text-action-blue opacity-75 p-4 font-bold;
   }

/*****************************
 **** Uploading progress
 ***************************/


.progress-circle {
  max-width: 100px;
  max-height: 100px;
  width: 100%;
  transform: scaleX(-1) rotate(-55deg);
}

.progress-circle__percent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.progress-circle__container {
  display: inline-block;
  position: relative;
}

.progress-circle__path {
  transition: 0.5s ease-in-out all;
}

.container {
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media screen and (min-width: 500px) {
  .container {
    flex-direction: row;
  }

  .progress-circle__button {
    margin-top: 0;
  }
}

</style>

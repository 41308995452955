import APIService from "./api.service";

const BASE_NAME = "/school";

const SchoolService = {
  getAll(resource) {
    return APIService.getAll(`${BASE_NAME}/${resource}`);
  },

  get(resource, id) {
    return APIService.get(`${BASE_NAME}/${resource}/${id}`);
  },

  create(resource, data) {
    return APIService.post(`${BASE_NAME}/${resource}`, data);
  },

  update(resource, id, data) {
    return APIService.put(`${BASE_NAME}/${resource}/${id}`, data);
  },

  updateWithPath(path, data) {
    return APIService.put(`${BASE_NAME}/${path}`, data);
  },

  delete(resource, id, data) {
    return APIService.delete(`${BASE_NAME}/${resource}/${id}`, data);
  }
};

const BaseService = {
  getAll(resource) {
    return APIService.getAll(`/${resource}`);
  },

  get(resource) {
    return APIService.getAll(`/${resource}`);
  },

  create(resource, data) {
    return APIService.post(`${resource}`, data);
  },

  update(resource, data) {
    return APIService.put(`${resource}`, data);
  }
};

export { SchoolService, BaseService };

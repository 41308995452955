import Vue from "vue";
import store from "@/store";
import Router from "vue-router";

const Login = () => import("@/views/Login");
const Planning = () => import("@/views/Planning");
const UnitView = () => import("@/views/UnitView");
const MyAccount = () => import("@/views/MyAccount");
const PlanningEdit = () => import("@/views/PlanningEdit");
const Events = () => import("@/views/Events");
const EventView = () => import("@/views/EventView");

const Reports = () => import("@/views/Reports");

const Activities = () => import("@/views/Activities");
const ActivityView = () => import("@/views/ActivityView");

const Resources = () => import("@/views/Resources");
const Videos = () => import("@/views/Videos");

const Pupils = () => import("@/views/Pupils");
const PupilEdit = () => import("@/views/PupilEdit");

Vue.use(Router);

let router = new Router({
  routes: [
    {
      path: "/Login",
      name: "Login",
      component: Login,
      meta: {
        loginLayout: true,
        hideHeader: true
      }
    },
    {
      path: "/",
      name: "Planning",
      component: Planning,
      meta: {
        requiresAuth: true,
        hideFromOrg: true,
        title: "Long Term Planning"
      }
    },
    {
      path: "/planning-edit",
      name: "PlanningEdit",
      component: PlanningEdit,
      props: true,
      meta: {
        requiresAuth: true,
        title: "Long Term Planning",
        hideHeader: true
      }
    },
        {
      path: "/unit-view",
      name: "UnitView",
      component: UnitView,
      props: true,
      meta: {
        requiresAuth: true,
        hideHeader: true
      }
    },
    {
      path: "/account",
      name: "MyAccount",
      component: MyAccount,
      meta: {
        requiresAuth: true,
        title: "Pupil Upload",
        subnav: "config",
        showSide: true
      }
    },
    {
      path: "/resources",
      name: "resources",
      component: Resources,
      meta: {
        requiresAuth: true,
        title: "Resource Library"
      }
    },
    {
      path: "/videos",
      name: "videos",
      component: Videos,
      meta: {
        requiresAuth: true,
        title: "Video Library"
      }
    },
    {
      path: "/Pupils",
      name: "Pupils",
      component: Pupils,
      props: true,
      meta: {
        requiresAuth: true,
        title: "Pupil Register",
        showSide: true
      }
    },
    {
      path: "/pupil",
      name: "PupilEdit",
      component: PupilEdit,
      props: true,
      meta: {
        requiresAuth: true,
        hideHeader: true
      }
    },

    {
      path: "/events",
      name: "Events",
      component: Events,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/event",
      name: "EventView",
      component: EventView,
      props: true,
      meta: {
        requiresAuth: true,
        hideHeader: true
      }
    },
    {
      path: "/reports",
      name: "Reports",
      component: Reports,
      meta: {
        requiresAuth: true,
        title: "Reports",
      }
    },
   
    {
      path: "/activities",
      name: "Activities",
      component: Activities,
      meta: {
        requiresAuth: true,
        title: "Extra Currciular"
      }
    },
    {
      path: "/activity",
      name: "ActivityView",
      component: ActivityView,
      props: true,
      meta: {
        requiresAuth: true,
        hideHeader: true
      }
    },
   
  ]
});

///////////////////////////////////
// CHECKS ROUTES FOR VALID META //
//////////////////////////////////

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // check token

    if (store.getters.isLoggedIn == false) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath }
      });
    } else {
      if (to.matched.some(record => record.meta.hideFromOrg)) {
        if (store.getters.currentUser.isSchool == false) {
          next({ path: "/dashboard" });
        } else {
          next();
        }
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

export default router;

import axios from "axios";
import router from "@/router";
import store from "@/store";

const API_URL =   'https://api.primarypepassport.co.uk:9443' // process.env.VUE_APP_ROOT_API // 
const PREFIX = "/api/v2";

const instance = axios.create({
  baseURL: API_URL + PREFIX,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json;charset=UTF-8",
   // "Access-Control-Allow-Origin": "*",
  }
});

/***********
 * Interceptor to ensure token is checked
 *************/

instance.interceptors.request.use(
  config => {
    let token = store.getters.token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const UNAUTHORIZED = 401;
const FORBIDDEN = 403;
instance.interceptors.response.use(
  response => response,
  error => {
    const { status } = error.response;
    if (status === UNAUTHORIZED || status == FORBIDDEN) {

      store.dispatch("logout");
      router.push("/login");
    }

    return Promise.reject(error);
  }
);

const APIService = {
  getAll(resource) {
    return instance.get(resource);
  },

  get(resource) {
    return instance.get(resource);
  },

  post(resource, data) {
    return instance.post(resource, data);
  },

  put(resource, data) {
    return instance.put(resource, data);
  },

  delete(resource, data) {
    return instance.delete(resource, data);
  }
};

export default APIService;


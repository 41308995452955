export default class Access {
  static from(object) {
    try {
      return new Access(object);
    } catch (err) {
      console.log("error - no access");
      return err;
    }
  }

  constructor(initializer) {
    this.values = initializer;
  }

  get unitAccess() {
    return this.values.filter(mod => mod.value === "my-units").length > 0;
  }

  get outcomeAccess() {
    return this.values.filter(mod => mod.value === "outcome").length > 0;
  }

  get physicalAccess() {
    return this.values.filter(mod => mod.value === "physical").length > 0;
  }

  get configAccess() {
    return this.values.filter(mod => mod.value === "configuration").length > 0;
  }

  get healthAccess() {
    return this.values.filter(mod => mod.value === "health").length > 0;
  }

  hasModule(value) {
    return this.values.filter(mod => mod.value === value).length > 0;
  }

  get hasResourceModule() {
    return this.values.filter(mod => mod.value === "library").length > 0;
  }

  get hasTimelineModule() {
    return this.values.filter(mod => mod.value === "timeline").length > 0;
  }


}
